var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.isModeCreate
          ? _vm.$t("lbl_create_general_journal")
          : _vm.$t("lbl_update_general_journal")
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _vm.isModeEdit
                    ? _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.docNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.docNumber.decorator,
                                expression: "formRules.docNumber.decorator"
                              }
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formRules.docNumber.placeholder
                              ),
                              "read-only": ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.date.decorator,
                            expression: "formRules.date.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t(_vm.formRules.date.placeholder),
                          format: _vm.DATE_FORMAT_DD_MM_YYYY_HH_MM_SS,
                          "disabled-date": _vm.useDisabledFromTomorrow,
                          "show-time": true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.description.label) },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: {
                                  value:
                                    _vm.form.getFieldValue("description") || ""
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.description.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isModeEdit
                    ? _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.docReference.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.docReference.decorator,
                                expression: "formRules.docReference.decorator"
                              }
                            ],
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formRules.docReference.placeholder
                              ),
                              "read-only": ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isModeEdit
                    ? _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.source.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.source.decorator,
                                expression: "formRules.source.decorator"
                              }
                            ],
                            attrs: { "read-only": "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isModeEdit
                    ? _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.status.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.status.decorator,
                                expression: "formRules.status.decorator"
                              }
                            ],
                            attrs: { "read-only": "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.attachment.label) }
                    },
                    [
                      _vm.urlAttachment
                        ? [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "" + _vm.urlAttachment,
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.urlAttachment || "-") + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c("a-button", {
                                  attrs: {
                                    icon: "delete",
                                    loading: _vm.loading.attachment,
                                    type: "danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAttachment(
                                        _vm.urlAttachment
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : [
                            _c(
                              "a-upload",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.attachment.decorator,
                                    expression: "formRules.attachment.decorator"
                                  }
                                ],
                                attrs: {
                                  name: "file",
                                  action: _vm.Api.UploadGeneralJournal,
                                  headers: _vm.headers,
                                  "before-upload": _vm.beforeUpload
                                },
                                on: { change: _vm.handleChangeAttachment }
                              },
                              [
                                _c("a-button", { attrs: { icon: "upload" } }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_journal_lines")))])
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "primary", icon: "plus" },
                      on: { click: _vm.handleAddRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "danger" },
                      on: { click: _vm.showConfirmation }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "data-source": _vm.journalLines,
                  columns: _vm.columns,
                  "row-selection": {
                    onChange: _vm.onSelectChange,
                    selectedRowKeys: _vm.selectedRowKeys
                  },
                  scroll: { y: 700 },
                  loading: _vm.loading.table,
                  pagination: false
                },
                scopedSlots: _vm._u([
                  {
                    key: "accountId",
                    fn: function(text, record) {
                      return _c("SelectAccountChild", {
                        staticClass: "w-100",
                        model: {
                          value: record.accountId,
                          callback: function($$v) {
                            _vm.$set(record, "accountId", $$v)
                          },
                          expression: "record.accountId"
                        }
                      })
                    }
                  },
                  {
                    key: "description",
                    fn: function(text, record) {
                      return _c("a-input", {
                        staticClass: "w-100",
                        attrs: { value: record.description },
                        on: {
                          change: function(e) {
                            return _vm.onChangeDescription(e, record)
                          }
                        }
                      })
                    }
                  },
                  {
                    key: "debit",
                    fn: function(text, record) {
                      return _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          value: record.debit,
                          formatter: _vm.formatterNumber,
                          parser: _vm.reverseFormatNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace,
                          disabled: record.credit !== 0
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeDebit(e, record)
                          }
                        }
                      })
                    }
                  },
                  {
                    key: "credit",
                    fn: function(text, record) {
                      return _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          value: record.credit,
                          formatter: _vm.formatterNumber,
                          parser: _vm.reverseFormatNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace,
                          disabled: record.debit !== 0
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeCredit(e, record)
                          }
                        }
                      })
                    }
                  }
                ])
              }),
              _c("p", { staticClass: "text-right mt-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("lbl_total_items", {
                        total: _vm.journalLines.length
                      })
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_debit"),
                  value: _vm.getTotalDebit || 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_credit"),
                  value: _vm.getTotalCredit || 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.idJournal &&
                  _vm.isManual &&
                  _vm.$can("delete", "general-journal")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loading.delete
                          },
                          on: { click: _vm.confirmDelete }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                      )
                    : _vm._e(),
                  _vm.isSubmitted &&
                  (_vm.$can("create", "general-journal") ||
                    _vm.$can("update", "general-journal"))
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.posting
                          },
                          on: { click: _vm.handlePosting }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_posting")) + " ")]
                      )
                    : _vm._e(),
                  _vm.$can("create", "general-journal") ||
                  _vm.$can("update", "general-journal")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading.save },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }