































































































































































































































import { SelectAccountChild } from "@/components/AccountingAccount";
import { useCoa, useGeneralJournal } from "@/hooks";
import { useDisabledFromTomorrow } from "@/hooks/datepicker";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Api } from "@/models/class/api.class";
import { MAX_FILE_SIZE } from "@/models/constant/global.constant";
import { DATE_FORMAT_DD_MM_YYYY_HH_MM_SS } from "@/models/constants/date.constant";
import {
  GENERAL_JOURNAL_SOURCE,
  GENERAL_JOURNAL_STATUS,
} from "@/models/enums/general-journal.enum";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { RequestDeleteFile } from "@/models/interface/assets.interface";
import {
  DataListJournalLines,
  DetailGeneralJournal,
  JournalLineDTOS,
  RequestGeneralJournal,
} from "@/models/interface/generaljournal.interface";
import { assetsServices } from "@/services/assets.service";
import { generalJournalServices } from "@/services/generaljournal.service";
import {
  changeCurrencytoNumeric,
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import Vue from "vue";
import { mapState } from "vuex";

type Row = {
  key: number;
  accountId: {
    key: string;
    label: string;
  };
} & Omit<DataListJournalLines, "accountId">;

export default Vue.extend({
  name: "CreateJournal",
  components: {
    SelectAccountChild,
  },
  mixins: [MNotificationVue],
  data() {
    return {
      form: this.$form.createForm(this, {
        name: "generalJournal",
      }) as WrappedFormUtils,
      urlAttachment: "",
      Api,
      DATE_FORMAT_DD_MM_YYYY_HH_MM_SS,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      selectedRowKeys: [] as number[],
      mode: Mode.CREATE as string,
      limit: 10 as number,
      uploadProses: false as boolean,
      deletedLineIds: [] as string[],
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
      },
      journalLines: [] as Row[],
      columns: [
        {
          title: this.$t("lbl_account_name"),
          dataIndex: "accountId",
          key: "accountId",
          scopedSlots: { customRender: "accountId" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          scopedSlots: { customRender: "description" },
        },
        {
          title: this.$t("lbl_debit"),
          dataIndex: "debit",
          key: "debit",
          scopedSlots: { customRender: "debit" },
        },
        {
          title: this.$t("lbl_credit"),
          dataIndex: "credit",
          key: "credit",
          scopedSlots: { customRender: "credit" },
        },
      ],
      formRules: {
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_date",
          decorator: [
            "date",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        docReference: {
          label: "lbl_document_reference",
          name: "docReference",
          placeholder: "lbl_document_reference",
          decorator: [
            "documentReference",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        attachment: {
          label: "lbl_attachment",
          name: "attachment",
          placeholder: "lbl_attachment",
          decorator: [
            "attachment",
            {
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        docNumber: {
          label: "lbl_document_number",
          name: "documentNumber",
          placeholder: "lbl_document_number",
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        source: {
          label: "lbl_source",
          name: "source",
          decorator: [
            "source",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      loading: {
        delete: false,
        table: false,
        save: false,
        posting: false,
        attachment: false,
      },
      detailJournal: {
        source: "",
      } as DetailGeneralJournal,
      idJournal: "",
    };
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    isModeCreate(): boolean {
      return this.mode === Mode.CREATE;
    },
    isModeEdit(): boolean {
      return this.mode === Mode.EDIT;
    },
    isSubmitted(): boolean {
      return this.detailJournal.status === GENERAL_JOURNAL_STATUS.SUBMITTED;
    },
    getTotalCredit(): number {
      return this.journalLines.reduce((a, b) => {
        return new Decimal(b.credit || 0).plus(a).toNumber();
      }, 0);
    },
    getTotalDebit(): number {
      return this.journalLines.reduce((a, b) => {
        return new Decimal(b.debit || 0).plus(a).toNumber();
      }, 0);
    },
    isManual(): boolean {
      return (
        this.detailJournal.source.toUpperCase() ===
        GENERAL_JOURNAL_SOURCE.MANUAL.toUpperCase()
      );
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    if (this.$route.params.id) {
      this.idJournal = this.$route.params.id;
    }
  },
  mounted() {
    if (this.idJournal) {
      this.getDetailJournal(this.idJournal);
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    useDisabledFromTomorrow,
    onChangeDescription(value, row: Row): void {
      row.description = value.target.value;
    },
    onChangeDebit(value: number, row: Row): void {
      row.debit = value;
    },
    onChangeCredit(value: number, row: Row): void {
      row.credit = value;
    },
    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
    deleteAttachment(fileName: string): void {
      this.loading.attachment = true;
      const payload: RequestDeleteFile = {
        bucketName: "generaljournal",
        objectName: fileName,
      };
      assetsServices
        .deleteUploadedFile(payload)
        .then(() => {
          this.showNotifSuccess("notif_delete_success");
          this.urlAttachment = "";
        })
        .finally(() => (this.loading.attachment = false));
    },
    getDetailJournal(id: string): void {
      this.loading.table = true;
      generalJournalServices
        .detailGeneralJournal(id)
        .then(doc => {
          this.detailJournal = doc;
          this.setForm(this.detailJournal);
          this.setTable(this.detailJournal.journalLineDTOS);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    setForm(detailJournal: DetailGeneralJournal): void {
      const { date, description, name, documentReference, source, status } =
        detailJournal;
      this.form.setFieldsValue({
        date: moment(date),
        description,
        name,
        documentReference,
        source,
        status,
      });
      this.urlAttachment = detailJournal.attachment || "";
    },
    setTable(journalLineDTOS: JournalLineDTOS[]): void {
      const { toLabel } = useCoa();
      this.journalLines = journalLineDTOS.map((x, i) => ({
        key: i,
        accountId: {
          label: toLabel({ code: x.accountCode, desc: x.accountDescription }),
          key: x.accountId,
        },
        accountDescription: x.accountDescription,
        code: x.accountCode,
        description: x.description,
        debit: x.debit,
        credit: x.credit,
        id: x.id,
      }));
    },
    updateGeneralJournal(id: string, payload: RequestGeneralJournal): void {
      this.loading.save = true;
      generalJournalServices
        .updateGeneralJournal(id, payload)
        .then(() => {
          this.showNotifSuccess("notif_update_success");
          this.getDetailJournal(id);
        })
        .catch(() => {
          this.showNotifError(this.$t("notif_update_fail"));
        })
        .finally(() => {
          this.loading.save = false;
        });
    },
    createGeneralJournal(payload: RequestGeneralJournal): void {
      this.loading.save = true;
      generalJournalServices
        .createGeneralJournal(payload)
        .then(response => {
          this.showNotifSuccess("notif_create_success", {
            documentNumber: response.message,
          });
          this.$router.push({ name: "generaljournal.read" });
        })
        .catch(() => {
          this.showNotifError(this.$t("notif_create_fail"));
        })
        .finally(() => (this.loading.save = false));
    },
    postingGeneralJournal(id: string, payload: RequestGeneralJournal): void {
      this.loading.posting = true;
      generalJournalServices
        .postingGeneralJournal(id, payload)
        .then(res => {
          this.showNotifSuccess("notif_update_success", {
            documentNumber: res.message,
          });
          this.$router.push({ name: "generaljournal.detail", params: { id } });
        })
        .finally(() => (this.loading.posting = false));
    },
    handlePosting(): void {
      this.form.validateFields((err, res) => {
        if (err && err.length) return;
        if (this.validateTable()) {
          this.showNotifError("lbl_general_journal_table_required");
          return;
        }
        this.postingGeneralJournal(
          this.idJournal,
          this.createPayload({
            ...res,
            total: this.getTotalCredit,
            journalLineDTOS: this.journalLines,
            attachment: this.urlAttachment,
          })
        );
      });
    },
    createPayload(val): RequestGeneralJournal {
      return {
        attachment: val.attachment,
        conversionDTO: {
          currency: "IDR",
          defaultCurrencyRate: 1,
        },
        date: val.date,
        description: val.description,
        documentReference: val.documentReference,
        journalLineDTOS: val.journalLineDTOS.map((row: Row) => ({
          description: row.description,
          accountId: row.accountId.key,
          credit: row.credit,
          debit: row.debit,
          id: row.id,
        })),
        source: val.source,
        total: val.total,
        deletedLineIds: this.deletedLineIds,
      };
    },
    handleBack() {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push({ name: "generaljournal.read" });
        },
        onCancel() {
          return;
        },
      });
    },
    validateTable(): boolean {
      if (!this.journalLines.length) return true;
      const invalidRow = this.journalLines.find(
        x => !x.accountId.key || x.debit === null || x.credit === null
      );
      return !!invalidRow;
    },
    handleSave() {
      this.form.validateFields((err, res) => {
        if (err) return;
        if (this.validateTable()) {
          this.showNotifError("lbl_general_journal_table_required");
          return;
        }

        const totalCredit = this.getTotalCredit || 0;
        if (this.isModeCreate) {
          this.createGeneralJournal(
            this.createPayload({
              ...res,
              attachment: this.urlAttachment,
              journalLineDTOS: this.journalLines,
              documentReference: null,
              source: GENERAL_JOURNAL_SOURCE.MANUAL,
              total: totalCredit,
            })
          );
        } else if (this.isModeEdit) {
          this.updateGeneralJournal(
            this.idJournal,
            this.createPayload({
              ...res,
              total: totalCredit,
              journalLineDTOS: this.journalLines,
              attachment: this.urlAttachment,
            })
          );
        }
      });
    },
    onSelectChange(value: number[]) {
      this.selectedRowKeys = value;
    },
    showConfirmation() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm"),
          content: this.$t("lbl_modal_delete_info", {
            count: this.selectedRowKeys.length,
          }),
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.showNotifError("lbl_modal_delete_error_description");
      }
    },
    handleDeleteRow() {
      this.journalLines = this.journalLines.filter(data => {
        if (data.id && this.selectedRowKeys.includes(data.key)) {
          this.deletedLineIds.push(data.id);
        }
        return !this.selectedRowKeys.includes(data.key);
      });
      this.journalLines.forEach((data, index) => {
        data.key = index;
      });
      this.journalLines = this.journalLines.slice();
      this.selectedRowKeys = [];
    },
    handleAddRow() {
      this.journalLines.push({
        key: this.journalLines.length,
        accountId: { key: "", label: "" },
        code: "",
        description: "",
        debit: 0,
        credit: 0,
        id: "",
      });
      this.journalLines.push({
        key: this.journalLines.length,
        accountId: { key: "", label: "" },
        code: "",
        description: "",
        debit: 0,
        credit: 0,
        id: "",
      });
    },
    handleChangeAttachment(info) {
      if (info.file.status !== "uploading") {
        this.uploadProses = true;
      }
      if (info.file.status === "done") {
        this.uploadProses = false;
        this.urlAttachment = info.file.response.url;
        this.showNotifSuccess("notif_file_upload_successfully", {
          filename: info.file.name,
        });
      } else if (info.file.status === "error") {
        this.uploadProses = true;
        this.showNotifError("notif_file_upload_failed", {
          filename: info.file.name,
        });
      }
    },
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= MAX_FILE_SIZE) {
        this.showNotifError("lbl_message_file_size_exceeded", {
          fileSize: MAX_FILE_SIZE,
        });
        return false;
      }
      return true;
    },
    confirmDelete() {
      this.showConfirmationCancel(() => this.handleDelete(this.idJournal));
    },
    handleDelete(secureId: string) {
      const { deleteJournal } = useGeneralJournal();
      this.loading.delete = true;
      deleteJournal(secureId)
        .then(() => {
          this.showNotifSuccess("notif_delete_success");
          this.$router.push({ name: "generaljournal.read" });
        })
        .finally(() => {
          this.loading.delete = false;
        });
    },
  },
});
